import React from 'react';
import ModalLayout from "./ModalLayout.react";
import SiteWrapper from "../SiteWrapper.react";
import { Crud_Catalogos } from '../herramientas/Crud_Catalogos';
import { PersonasForm } from "../Catalogos";
import { Form, Grid, Card, Table, Button, Icon, Dimmer, List } from "tabler-react";
import LoadStore from "../store/LoadStore";
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
/**
 * Notificaciones
 */
import { NotificationContainer, NotificationManager} from 'react-notifications';

var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
class Tracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
              elementos: [],
       tableheaderItems: [],
           hiddenModals: false,
                booking: "",
               columnas: [],
                  patio: {},
                gpsFull: [],
          capLogisticos: [],
            selectedRow: -1,
             currentMov: null,
              contactos: []
    };
    this.contenedorTipo={};
    this.content = [  { content: "id", col: "id" },
                      { content: "Movimiento", col: "idMovimientoTipo" },
                      { content: "contenedor", col: "contenedor" },
                      { content: "sello" , col: "sello"},
                      { content: "size", col: "contenedorSize" },
                      { content: "type", col: "contenedorTipo" },
                      { content: "Det", col: "det"},
                      { content: "Transportista", col: "idTransportista" },
                      { content: "Estatus", col: "idRuta" },
                      { content: "Cita Programada", col: "citaProgramada" },
                    ];
    this.impo    = [  { content: <span><div>Arribo de camión a Terminal para retirar contenedor</div><div align="center"><img src="images/llegadaFrontera.png" alt="llegadaFrontera" width="20%" height="20%"/></div> </span>, col: "arriboTerminalVacio" },
                      { content: <span><div>Salida de camión de Terminal con contenedor cargado</div><div align="center"><img src="images/fronteraPuerto.png" alt="fronteraPuerto" width="20%" height="20%"/></div> </span>, col: "salidaTerminalLleno" },
                      { content: <span><div>Inicio de Ruta</div><div align="center"><img src="images/bodegaFrontera.png" alt="bodegaFrontera" width="20%" height="20%"/></div> </span>,col: "inicioRuta" },
                      { content: <span><div>Arribo a dirección de entrega</div><div align="center"><img src="images/bodegaFrontera.png" alt="bodegaFrontera" width="20%" height="20%"/></div> </span>, col: "arriboClienteLleno" },
                      { content: <span><div>Salida de contenedor vacio de Cliente</div><div align="center"><img src="images/bodegaVacio.png" alt="bodegaVacio" width="20%" height="20%"/></div> </span>, col: "salidaClienteVacio" },
                    ];
    this.impocrs = [  { content: <span><div>Arribo de camión a Terminal para retirar contenedor</div><div align="center"><img src="images/llegadaFrontera.png" alt="llegadaFrontera" width="20%" height="20%"/></div> </span>, col: "arriboTerminalVacio" },
                    { content: <span><div>Salida de camión de Terminal con contenedor cargado</div><div align="center"><img src="images/fronteraPuerto.png" alt="fronteraPuerto" width="20%" height="20%"/></div> </span>, col: "salidaTerminalLleno" },
                    { content: <span><div>Inicio de Ruta</div><div align="center"><img src="images/bodegaFrontera.png" alt="bodegaFrontera" width="20%" height="20%"/></div> </span>,col: "inicioRuta" },
                    { content: <span><div>Llegada a frontera</div><div align="center"><img src="images/bodegaFrontera.png" alt="bodegaFrontera" width="20%" height="20%"/></div> </span>,col: "llegadaFrontera" },
                    { content: <span><div>Salida de Frontera</div><div align="center"><img src="images/bodegaFrontera.png" alt="bodegaFrontera" width="20%" height="20%"/></div> </span>,col: "salidaFrontera" },
                    { content: <span><div>Arribo a dirección de entrega</div><div align="center"><img src="images/bodegaFrontera.png" alt="bodegaFrontera" width="20%" height="20%"/></div> </span>, col: "arriboClienteLleno" },
                    { content: <span><div>Salida de contenedor vacio de Cliente</div><div align="center"><img src="images/bodegaVacio.png" alt="bodegaVacio" width="20%" height="20%"/></div> </span>, col: "salidaClienteVacio" },
                  ];                    
    this.expo    = [  { content: <span><div>Arribo contenedor vacio con Cliente</div><div align="center"><img src="images/bodegaVacio.png" alt="bodegaVacio" width="20%" height="20%"/></div> </span>, col: "arriboClienteVacio" },
                      { content: <span><div>Salida de contenedor cargado de Cliente</div><div align="center"><img src="images/llegadaFrontera.png" alt="llegadaFrontera" width="20%" height="20%"/></div> </span>, col: "salidaClienteLleno" },
                      { content: <span><div>Arribo de Contenedor Cargado a Patio de la Linea</div><div align="center"><img src="images/bodegaFrontera.png" alt="bodegaFrontera" width="20%" height="20%"/></div> </span>,col: "arriboPatioLinea" },
                      { content: <span><div>Arribo de camión a Terminal para dejar contenedor cargado</div><div align="center"><img src="images/llegadaFrontera.png" alt="llegadaFrontera" width="20%" height="20%"/></div> </span>, col: "arriboTerminalCargado" },
                    ];
    this.expocrs = [  { content: <span><div>Arribo contenedor vacio con Cliente</div><div align="center"><img src="images/bodegaVacio.png" alt="bodegaVacio" width="20%" height="20%"/></div> </span>, col: "arriboClienteVacio" },
                      { content: <span><div>Salida de contenedor cargado de Cliente</div><div align="center"><img src="images/llegadaFrontera.png" alt="llegadaFrontera" width="20%" height="20%"/></div> </span>, col: "salidaClienteLleno" },
                      { content: <span><div>Llegada a frontera</div><div align="center"><img src="images/bodegaFrontera.png" alt="bodegaFrontera" width="20%" height="20%"/></div> </span>,col: "llegadaFrontera" },
                      { content: <span><div>Salida de Frontera</div><div align="center"><img src="images/bodegaFrontera.png" alt="bodegaFrontera" width="20%" height="20%"/></div> </span>,col: "salidaFrontera" },
                      { content: <span><div>Arribo de Contenedor Cargado a Patio de la Linea</div><div align="center"><img src="images/bodegaFrontera.png" alt="bodegaFrontera" width="20%" height="20%"/></div> </span>,col: "arriboPatioLinea" },
                      { content: <span><div>Arribo de camión a Terminal para dejar contenedor cargado</div><div align="center"><img src="images/llegadaFrontera.png" alt="llegadaFrontera" width="20%" height="20%"/></div> </span>, col: "arriboTerminalCargado" },
                  ];
  } 

  componentWillMount=() =>{
    this.getfuntion("get", "", "", "contenedorTipo", "contenedorTipo" );
  }
  componentDidMount = () => {
    /*
    currentUser.map((usr, index)=>{this.plataformas.push( {value: usr.plataforma.id,  label:usr.plataforma.nombre } ) });
    if( currentUser.length > 0 ){ this.setState({ plataforma: {value: currentUser[0].plataforma.id,  label:currentUser[0].plataforma.nombre } }) }
    */
  }
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  }

  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas/plataforma/"+(this.state.currentMov.idPlataforma?this.state.currentMov.idPlataforma.id: null) , stateVar = "personas", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "tracking", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
            if (stateVar === "contenedorTipo") {
              returnVal.map((ct, index)=> this.contenedorTipo[ct.id]= ct.nombre  );
            }
            if (stateVar === "navieraMovimientos") {
              if(returnVal[0]){
                for(let mov of returnVal){
                  this.getfuntion("get", mov, "", "movimientosPatio/idMovimiento/" + mov.id, "movPatio" );
                }
                let motipoTemp= returnVal[0].idMovimientoTipo.id === 3? 1: returnVal[0].idMovimientoTipo.id === 4? 2: returnVal[0].idMovimientoTipo.id;
                this.getfuntion("get", "", "", "camposLogisticos/plataforma/" + returnVal[0].idPlataforma.id+"/mtipo/"+motipoTemp, "camposLogisticos" );
                //https://one.skynavieros.com:8080/skyone/camposLogisticos/plataforma/3/mtipo/1
                this.setState({elementos: returnVal});
                if( [325,327,227,326].includes(returnVal[0].idPlataforma.id) ){
                  this.expo    = [  { content: <span><div>Arribo contenedor vacio con Cliente</div><div align="center"><img src="images/bodegaVacio.png" alt="bodegaVacio" width="20%" height="20%"/></div> </span>, col: "arriboClienteVacio" },
                      { content: <span><div>Salida de contenedor cargado de Cliente</div><div align="center"><img src="images/llegadaFrontera.png" alt="llegadaFrontera" width="20%" height="20%"/></div> </span>, col: "salidaClienteLleno" },
                      { content: <span><div>Arribo de camión a Terminal para dejar contenedor cargado</div><div align="center"><img src="images/llegadaFrontera.png" alt="llegadaFrontera" width="20%" height="20%"/></div> </span>, col: "arriboTerminalCargado" },
                    ];
                  this.expocrs = [  { content: <span><div>Arribo contenedor vacio con Cliente</div><div align="center"><img src="images/bodegaVacio.png" alt="bodegaVacio" width="20%" height="20%"/></div> </span>, col: "arriboClienteVacio" },
                    { content: <span><div>Salida de contenedor cargado de Cliente</div><div align="center"><img src="images/llegadaFrontera.png" alt="llegadaFrontera" width="20%" height="20%"/></div> </span>, col: "salidaClienteLleno" },
                    { content: <span><div>Llegada a frontera</div><div align="center"><img src="images/bodegaFrontera.png" alt="bodegaFrontera" width="20%" height="20%"/></div> </span>,col: "llegadaFrontera" },
                    { content: <span><div>Salida de Frontera</div><div align="center"><img src="images/bodegaFrontera.png" alt="bodegaFrontera" width="20%" height="20%"/></div> </span>,col: "salidaFrontera" },
                    { content: <span><div>Arribo de camión a Terminal para dejar contenedor cargado</div><div align="center"><img src="images/llegadaFrontera.png" alt="llegadaFrontera" width="20%" height="20%"/></div> </span>, col: "arriboTerminalCargado" },
                  ];
                }
                switch (returnVal[0].idMovimientoTipo.id) {
                  case 1:
                    this.setState({columnas: this.content.concat(this.impo) });  
                    break;
                  case 2:
                    this.setState({columnas: this.content.concat(this.expo) });
                    break;
                  case 3:
                    this.setState({columnas: this.content.concat(this.impocrs) });
                    break;
                  case 4:
                    this.setState({columnas: this.content.concat(this.expocrs) });
                    break;
                  default:
                    break;
                }
              }
            }
            if (stateVar === "movPatio" && returnVal) {
              let patioTem= this.state.patio;
              patioTem[returnVal.idMovimiento]= returnVal;
              this.setState({patio: patioTem });
              this.setState({gpsFull: [] });
              if( !obj.movimientoGps[0] && returnVal.esFull ){
                this.getfuntion("get", "", "", "movimientoGps/movimiento/" + parseInt(returnVal.esFull, 10), "movimientoGps" );
              }
            }
            if(stateVar === "movimientoGps"){
              this.setState({ gpsFull: returnVal});
            }
            if( stateVar === "camposLogisticos"){
            }
            if (stateVar === "urlGps") {
              window.open(returnVal.url, 'sharer', 'width=900,height=600,scrollbars=NO');
            }
            if(stateVar === "contactos"){
              this.setState({ contactos: returnVal});
            }
          break;
        case "post":
            if (stateVar === "personas") { 
              if(returnVal.id){
                
                let idPlataformaTem= this.state.currentMov.idPlataforma?this.state.currentMov.idPlataforma.id: null
                this.getfuntion("post", this.state.currentMov.idCliente, "", "contacto/"+idPlataformaTem+"/agregar?mail="+returnVal.correo+"&idMovimiento="+this.state.currentMov.id, "solicitud" );
                let contactosTem= this.state.contactos; 
                contactosTem.unshift( returnVal );
                //this.hiddenmodal();
                this.setState({ contactos: contactosTem });
                this.getfuntion("post", { "idCliente": this.state.currentMov.idCliente, "idPersona": returnVal }, "", "clientesContactos", "clientesContactos", this.hiddenmodal);        
              }
            }
            if (stateVar === "clientesContactos") {
              if(returnVal.idPersona){
                NotificationManager.info("Contacto agregado, ingrese a su correo ("+returnVal.idPersona.correo+" ) para validar", "Nuevo");
              }              
            }
          break;
        case "put":
            if (stateVar === "clientes") { 
              if(returnVal.id){
                NotificationManager.info("Se han agregado las modificaciones del cliente al catlogo general( "+returnVal.nombre+" )", "Modificación");
                let clientesTem= this.state.Clientes;
                for(let item of clientesTem){
                  if(item.id === returnVal.id){
                    item= returnVal;
                  }
                }
                this.setState({ Clientes: clientesTem });  
                this.hiddenmodal();           
              }
            }
          break;
        case "delete":
            if (stateVar === "clientes") {
              if(returnVal){
                this.getfuntion("delete", { "idPlataforma": this.props.elementos.id, "idCliente": id }, this.props.elementos.id+"/"+id, "plataformasClientes", "plataformasClientes")              
              }
            }
            if (stateVar === "clientesContactos") {
              return returnVal;
            }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err) });
  }
  getMovimiento=()=>{
    this.getfuntion("get", [], "", "navieraMovimientos/booking?booking="+this.state.booking, "navieraMovimientos");
    this.setState({booking: "" });
    this.setState({elementos: []});
    this.setState({currentMov: null });
    this.setState({ selectedRow: -1 });
  }
  setBooking=(e)=>{
    this.setState({booking: e.target.value });
  }
  tracking =(mov) =>{
    let idGGTem= this.state.currentMov.idPlataforma.id? this.state.currentMov.idPlataforma.id: null;
    let gpsTemp=  this.state.currentMov.movimientoGps[0]? 
                    this.state.currentMov.movimientoGps[0].gps.id
                  : this.state.gpsFull[0]? this.state.gpsFull[0].gps.id:null ;
    if( idGGTem && gpsTemp ){
      this.getfuntion("get", "", "", "gpsestatus/rastreame/plataforma/"+idGGTem+"/gps/" + gpsTemp, "urlGps");
    }
  }
  deletedPersona=(personaT)=>{
    //this.getfuntion("get", [], "", "clientesContactos/booking/"+this.state.booking, "navieraMovimientos");
    this.getfuntion("post", this.state.currentMov.idCliente, "", "contacto/"+this.state.currentMov.idPlataforma.id+"/Borrar?mail="+personaT.correo, "solicitud" );
    this.getfuntion("delete", "", this.state.currentMov.idCliente.id+"/"+personaT.id, "clientesContactos/borrar", "clientesContactos").then((returnVal) => {
      this.getfuntion("delete", "", personaT.id, "personas", "personas");
    });
  }
  getColumna=(elemento)=>{
    let colum=[];
    for( let c of this.content){
      switch (c.col) {
        case "idMovimientoTipo":
          colum.push(<Table.Col> { elemento[c.col].nombre } </Table.Col>);
          break;
        case "idTransportista":
          colum.push(<Table.Col> { elemento[c.col].nombre } </Table.Col>);
          break;
        case "idRuta":
          colum.push(<Table.Col> { elemento[c.col].nombre } </Table.Col>);
          break;
        case "contenedorTipo":
          colum.push(<Table.Col> { elemento[c.col]? elemento[c.col].nombre:"" } </Table.Col>);
          break;
        case "det":
          colum.push(<Table.Col>
            <ModalLayout tamanio={'60%'} title="Movimiento"
              name={<span><Icon prefix="fa" name="bullhorn" /></span>}
              hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
              formulario={
                <List.Group>
                  <List.GroupItem action icon="truck"> Transportista:   <strong> { elemento['idTransportista']? elemento['idTransportista'].nombre:""} </strong> </List.GroupItem>
                  <List.GroupItem action icon="user"> Operador:         <strong> { this.state.patio[elemento.id]? this.state.patio[elemento.id].operadorNombre:"" } </strong> </List.GroupItem>
                  <List.GroupItem action icon="truck"> Tracto:          <strong> { this.state.patio[elemento.id]? this.state.patio[elemento.id].tractorPlaca: ""           } </strong> </List.GroupItem>
                  <List.GroupItem action icon="truck"> Remolque:        <strong> { this.state.patio[elemento.id]? this.state.patio[elemento.id].remolquePlaca: ""  } </strong> </List.GroupItem>
                  <List.GroupItem action icon="pocket"> Marca:          <strong> { this.state.patio[elemento.id]? this.state.patio[elemento.id].tractorMarca:""  } </strong> </List.GroupItem>
                  <List.GroupItem action icon="circle"> Color:          <strong> { this.state.patio[elemento.id]? this.state.patio[elemento.id].tractorColor: ""           } </strong> </List.GroupItem>
                  <List.GroupItem action icon="underline"> Económico Tracto: <strong> { this.state.patio[elemento.id]? this.state.patio[elemento.id].economicot: ""  } </strong> </List.GroupItem>
                  <List.GroupItem action icon="underline"> Económico Remolque: <strong> { this.state.patio[elemento.id]? this.state.patio[elemento.id].economicor: ""  } </strong> </List.GroupItem>
                </List.Group>
              }
            />
          </Table.Col>);
          break;      
        default:
          colum.push(<Table.Col> { elemento[c.col] } </Table.Col>);
          break;
      }
    }
    if(elemento.idMovimientoTipo.id === 1){
      this.impo.map((ci, indice2) =>{
        colum.push(
          <Table.Col>
            <span className={(  elemento[ci.col]? "text-success "
                                : this.impo[(indice2-1)]? elemento[this.impo[(indice2-1)].col] && !elemento[ci.col]? "text-primary "
                                  :"text-orange ":"text-orange "
                              )+"fa fa-circle m-6"} data-toggle="tooltip" data-placement="top" title={ elemento[ci.col] }></span> </Table.Col>);
        return ci;
      })
    }
    if( elemento.idMovimientoTipo.id === 2){
      this.expo.map((ci, indice2) =>{
        colum.push(
          <Table.Col>
            <span className={(  elemento[ci.col]? "text-success "
                                : this.expo[(indice2-1)]? elemento[this.expo[(indice2-1)].col] && !elemento[ci.col]? "text-primary " 
                                  :"text-orange ":"text-primary "
                              )+"fa fa-circle m-6"} data-toggle="tooltip" data-placement="top" title={ elemento[ci.col] }></span> </Table.Col>);
        return ci;
      })
    }
    if( elemento.idMovimientoTipo.id === 3){
      this.impocrs.map((ci, indice2) =>{
        colum.push(
          <Table.Col>
            <span className={(  elemento[ci.col]? "text-success "
                                : this.impocrs[(indice2-1)]? elemento[this.impocrs[(indice2-1)].col] && !elemento[ci.col]? "text-primary "
                                  :"text-orange ":"text-orange "
                              )+"fa fa-circle m-6"} data-toggle="tooltip" data-placement="top" title={ elemento[ci.col] }></span> </Table.Col>);
        return ci;
      })
    }
    if(elemento.idMovimientoTipo.id === 4){
      this.expocrs.map((ci, indice2) =>{
        colum.push(
          <Table.Col>
            <span className={(  elemento[ci.col]? "text-success "
                                : this.expocrs[(indice2-1)]? elemento[this.expocrs[(indice2-1)].col] && !elemento[ci.col]? "text-primary "
                                  :"text-orange ":"text-orange "
                              )+"fa fa-circle m-6"} data-toggle="tooltip" data-placement="top" title={ elemento[ci.col] }></span> </Table.Col>);
        return ci;
      })
    }
    return colum;
  }
    render() {
        return (
          <SiteWrapper>
            <div className="container-fluid" style={{ "background-color": "rgba(0, 0, 0, 0.66)" }}>
              {<LoadStore catalogos={["privilegios"]}/>}
              <NotificationContainer/>
              {/*
              <Nav  className="bg-primary text-light d-flex justify-content-center"
                    items={
                      <React.Fragment>
                        <Nav.Item to="http://www.cma-cgm.com/about/the-group">The Group</Nav.Item>
                        <Nav.Item to="http://www.cma-cgm.com/about/careers" value="| Careers" />
                        <Nav.Item to="http://www.cma-cgm.com/finance" value="| Investors" />
                        <Nav.Item to="http://www.cma-cgm.com/about/sustainability" value="| Sustainability" />
                        <Nav.Item to="http://www.cma-cgm.com/local-offices" value="| Network" />
                        <div data-toggle="tooltip" data-placement="top" title="Salir"><Nav.Item icon="log-out" to="/logout" ></Nav.Item></div>
                      </React.Fragment>
                    }
              /> 
                  */}
              <div className="mt-3 text-light" style={{ "background-color": "rgba(0, 0, 0, 0.66)"}}>
                <Grid.Row>
                  <Grid.Col sm={12} lg={12}><h2>Tracking Contenedor</h2></Grid.Col>
                  <Grid.Col sm={12} lg={12}><h4>Movimientos</h4></Grid.Col>
                  <Grid.Col sm={12} lg={12}>Se requiere capturar el campo de Booking para realizar la búsqueda</Grid.Col>
                  <Grid.Col sm={5} lg={4}><Form.Input className="mb-3" icon="search" placeholder="Buscar..." position="append" onChange={this.setBooking} value={this.state.booking} /></Grid.Col>
                  <Grid.Col sm={2} lg={2}><Button pill  size="sm" outline color="primary" onClick= { () => this.getMovimiento()} ><Icon name="search" />Buscar</Button></Grid.Col>
                </Grid.Row>
              </div>
              <div className="text-dark">             
                
                <Grid.Row>
                  <Grid.Col sm={12} lg={12} className="col-12 bg-secondary text-light">
                    <h4>Detalle de Unidad</h4>
                  </Grid.Col >
                  <Grid.Col xs={12} sm={12} lg={8} className="mt-3">
                    <List.Group>
                      <List.GroupItem action icon="item"> Booking:    <strong> { this.state.elementos[0]? this.state.elementos[0].booking: ""           } </strong> </List.GroupItem>
                      <List.GroupItem action icon="watch"> Corredor:  <strong> { this.state.elementos[0]? this.state.elementos[0].idCorredor.nombre:""  } </strong> </List.GroupItem>
                      <List.GroupItem action icon="clock"> Destino:   <strong> { this.state.elementos[0]? this.state.elementos[0].destino: ""           } </strong> </List.GroupItem>
                      <List.GroupItem action icon="clock"> Cliente:   <strong> { this.state.elementos[0]? this.state.elementos[0].idCliente.nombre: ""  } </strong> </List.GroupItem>
                    </List.Group>
                  </Grid.Col>                  
                  <Grid.Col xs={12} sm={12} lg={4} className="mt-3">
                    <List.Group>                    
                      <List.GroupItem action> <span className="text-success fa fa-circle mr-3"></span> <strong> Completado        </strong> </List.GroupItem>
                      <List.GroupItem action> <span className="text-primary fa fa-circle mr-3"></span> <strong> En proceso        </strong> </List.GroupItem>
                      <List.GroupItem action> <span className="text-orange  fa fa-circle mr-3"></span> <strong> No completado:   </strong> </List.GroupItem>
                    </List.Group>
                  </Grid.Col>                 
                    <Grid.Col xs={12} sm={12} lg={11} className="mt-3">
                      <Table  cards={true} striped={true} responsive={true} className="table-vcenter bg-light"
                              headerItems={this.state.columnas}>
                        <Table.Body>                
                        {this.state.elementos.length>0?
                          this.state.elementos.map((elemento, index) =>
                            <Table.Row   style={this.state.selectedRow === index ? { "background-color": "rgba(95, 231, 50, 0.30)" } : null} onClick={() => { this.setState({currentMov: elemento}); this.setState({ selectedRow: index }); }}>
                              {this.getColumna(elemento)}
                            </Table.Row>
                          ):null
                        }
                        </Table.Body>
                      </Table>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} lg={1} className={(this.state.currentMov?this.state.currentMov.vip?"text-primary":"text-secondary":"text-secondary")+" mt-3"} >
                      { ![7736].includes(currentUser[0]? currentUser[0].usuario.id:0)?
                        <div  className="fa fa-map-marker ml-2" style={{ 'font-size': '500%'}}
                              onClick={this.state.currentMov?this.state.currentMov.vip? ()=> this.tracking():null:null } 
                              data-toggle="tooltip" data-placement="top" title="Tracking">
                        </div>
                      :null}
                      <ModalLayout  tamanio={'60%'} title="Movimiento" disabled={this.state.currentMov?this.state.currentMov.vip? false:true:true }
                                    name={
                                      <span  className="fa fa-truck ml-2" style={{ 'font-size': '500%'}}
                                            data-toggle="tooltip" data-placement="top" title="Detalles de transporte">
                                      </span>
                                    }
                                    hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                    formulario={
                                      <Grid.Row>
                                        <Grid.Col>
                                          <List.Group>
                                            <List.GroupItem action> Booking:        <strong> {this.state.currentMov?this.state.currentMov.booking: ""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Po:             <strong> {this.state.currentMov?this.state.currentMov.po: ""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Contenedor:     <strong> {this.state.currentMov?this.state.currentMov.contenedor: ""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Sello:          <strong> {this.state.currentMov?this.state.currentMov.sello: ""} </strong> </List.GroupItem>
                                            <List.GroupItem action> GPS:            <strong> {  this.state.currentMov?
                                                                                                this.state.currentMov.movimientoGps[0]? this.state.currentMov.movimientoGps[0].gps.nombre:
                                                                                                this.state.gpsFull[0]? this.state.gpsFull[0].gps.nombre:""//full
                                                                                                : ""} </strong> </List.GroupItem>
                                          </List.Group>
                                          <div className="mt-8"></div>
                                          <List.Group>
                                            <List.GroupItem action> Destino:        <strong> {this.state.currentMov?this.state.currentMov.destino: ""} </strong> </List.GroupItem>
                                            <List.GroupItem action>  Dirrección:    <strong> {this.state.currentMov?this.state.currentMov.idCliente?this.state.currentMov.idCliente.domicilio: "": ""} </strong> </List.GroupItem>
                                          </List.Group>
                                          <div className="mt-8"></div>
                                          <List.Group>
                                            { this.state.currentMov?
                                                this.state.currentMov.idMovimientoTipo.id === 1?
                                                  this.impo.map((campos, index)=> campos.col !== "inicioRuta"?
                                                    <List.GroupItem action> {campos.content}        <strong> {this.state.currentMov[campos.col]} </strong> </List.GroupItem>:null)
                                                : null
                                            :null}
                                            { this.state.currentMov?
                                                this.state.currentMov.idMovimientoTipo.id === 2?
                                                  this.expo.map((campos, index)=> campos.col !== "inicioRuta"?
                                                    <List.GroupItem action> {campos.content}        <strong> {this.state.currentMov[campos.col]} </strong> </List.GroupItem>:null)
                                              :null
                                            :null}
                                            { this.state.currentMov?
                                                this.state.currentMov.idMovimientoTipo.id === 3?
                                                  this.impocrs.map((campos, index)=> campos.col !== "inicioRuta"?
                                                    <List.GroupItem action> {campos.content}        <strong> {this.state.currentMov[campos.col]} </strong> </List.GroupItem>:null)
                                                : null
                                            :null}
                                            { this.state.currentMov?
                                                this.state.currentMov.idMovimientoTipo.id === 4?
                                                  this.expocrs.map((campos, index)=> campos.col !== "inicioRuta"?
                                                    <List.GroupItem action> {campos.content}        <strong> {this.state.currentMov[campos.col]} </strong> </List.GroupItem>:null)
                                                : null
                                            :null}
                                          </List.Group>
                                          <div className="mt-8"></div>
                                          <List.Group>
                                            <List.GroupItem action> Estatus:        <strong> {this.state.currentMov?this.state.currentMov.idRuta?this.state.currentMov.idRuta.nombre: "": ""} </strong> </List.GroupItem>
                                          </List.Group>                                      
                                        </Grid.Col>
                                        <Grid.Col>
                                          <List.Group>
                                            <List.GroupItem action> Transportista:  <strong> {this.state.currentMov?this.state.currentMov.idTransportista?this.state.currentMov.idTransportista.nombre: "": ""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Económico Tracto:      <strong> {this.state.currentMov?this.state.patio[this.state.currentMov.id]? this.state.patio[this.state.currentMov.id].economicot: "":""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Económico Remolque:      <strong> {this.state.currentMov?this.state.patio[this.state.currentMov.id]? this.state.patio[this.state.currentMov.id].economicor: "":""} </strong> </List.GroupItem>
                                          </List.Group>
                                          <div className="mt-8"></div>
                                          <List.Group>
                                            <List.GroupItem action> Operador:       <strong> {this.state.currentMov?this.state.patio[this.state.currentMov.id]? this.state.patio[this.state.currentMov.id].operadorNombre: "":""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Medio:          <strong> {this.state.currentMov?this.state.patio[this.state.currentMov.id]? this.state.patio[this.state.currentMov.id].operadorContacto: "":""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Otro Medio:     <strong>  </strong> </List.GroupItem>
                                            <List.GroupItem action> Tractor:        <strong> {this.state.currentMov?this.state.patio[this.state.currentMov.id]? this.state.patio[this.state.currentMov.id].tractorPlaca: "":""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Remolque:       <strong> {this.state.currentMov?this.state.patio[this.state.currentMov.id]? this.state.patio[this.state.currentMov.id].remolquePlaca: "":""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Marca:          <strong> {this.state.currentMov?this.state.patio[this.state.currentMov.id]? this.state.patio[this.state.currentMov.id].tractorMarca: "":""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Color:          <strong> {this.state.currentMov?this.state.patio[this.state.currentMov.id]? this.state.patio[this.state.currentMov.id].tractorColor: "":""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Modelo:         <strong> {this.state.currentMov?this.state.patio[this.state.currentMov.id]? this.state.patio[this.state.currentMov.id].tractorModelo: "":""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Registro:       <strong> {this.state.currentMov?this.state.patio[this.state.currentMov.id]? this.state.patio[this.state.currentMov.id].colocacionFecha: "":""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Licencia:       <strong> {this.state.currentMov?this.state.patio[this.state.currentMov.id]? this.state.patio[this.state.currentMov.id].operadorLicencia: "":""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Vigencia:       <strong> {this.state.currentMov?this.state.patio[this.state.currentMov.id]? this.state.patio[this.state.currentMov.id].operadorLicencia: "":""} </strong> </List.GroupItem>
                                          </List.Group>
                                          <div className="mt-8"></div>
                                          <List.Group>
                                            <List.GroupItem action> Cliente:        <strong> {this.state.currentMov?this.state.currentMov.idCliente?this.state.currentMov.idCliente.nombre: "": ""} </strong> </List.GroupItem>
                                            <List.GroupItem action> Tel:            <strong> {this.state.currentMov?this.state.currentMov.idCliente?this.state.currentMov.idCliente.telefono: "": ""} </strong> </List.GroupItem>
                                          </List.Group>
                                          <div className="mt-8">Destinatarios</div>
                                          <List.Group>

                                          </List.Group>
                                        </Grid.Col>
                                      </Grid.Row>
                                    }
                      />
                      { ![7736].includes(currentUser[0]? currentUser[0].usuario.id:0)?
                        <ModalLayout  tamanio={'60%'} title="Movimiento" disabled={this.state.currentMov?this.state.currentMov.vip? false:true:true }
                                      name={
                                        <span  className="fa fa-envelope ml-2" style={{ 'font-size': '500%'}} onClick={()=> this.getfuntion("get", [], "", "personas/cliente/"+this.state.currentMov.idCliente.id, "contactos") }
                                              data-toggle="tooltip" data-placement="top" title="Notificación">
                                        </span>
                                      }
                                      hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                      formulario={
                                        <Grid.Row>
                                          <Grid.Col>                                        
                                            <Card
                                                  title={<h2>Contactos</h2>}
                                                  isCollapsible={false}
                                                  //isClosable
                                                  isCollapsed={false}
                                                  options=
                                                    {<div>
                                                      <span className="float-right ml-2" data-toggle="tooltip" data-placement="top" title={"Nuevo contacto"}>
                                                        <ModalLayout  tamanio={"70%"} hiddenModal={ this.state.hiddenModals } Fclose={this.hiddenmodal}
                                                                                      title= "Nuevo" name= {<Button pill  size="sm" outline color="primary"><Icon name="plus"/></Button>}
                                                                                      formulario= { <PersonasForm accion="nuevo" clienteFinal={true} getfuntion= {this.getfuntion} elementos= { [] } stor= { this.props } /> }
                                                                        />
                                                      </span>
                                                    </div>}
                                                  body={
                                                    <div>
                                                      <Form.Group>
                                                        <Grid.Row><Grid.Col><Dimmer active={false} loader></Dimmer></Grid.Col></Grid.Row>
                                                        <Table  cards={true} striped={true} responsive={true} className="table-vcenter"
                                                                headerItems={[{content:"nombre"}, {content: "correo"}, {content: "estatus"}]} >
                                                          <Table.Body>                              
                                                              {
                                                                this.state.contactos.sort().map((elemento, index) =>
                                                                  <Table.Row >
                                                                    <Table.Col> { elemento.aPaterno+" "+elemento.aMaterno+" "+elemento.nombre } </Table.Col>
                                                                    <Table.Col> {elemento.correo} </Table.Col>
                                                                    <Table.Col> <Form.Switch name="correoestatus" label=" " checked={elemento.correoEstatus} /> </Table.Col>
                                                                    <Table.Col>
                                                                      <Button pill  size="sm" outline color="primary"
                                                                              onClick={ ()=>
                                                                                confirmAlert({
                                                                                  title: 'Antención',
                                                                                  message: '¿Esta seguro de eliminar, '+ elemento.aPaterno+" "+elemento.aMaterno+" "+elemento.nombre+'?',
                                                                                  buttons: [
                                                                                    {
                                                                                      label: 'Confirmar',
                                                                                      onClick: () => { this.deletedPersona(elemento) }
                                                                                    },
                                                                                    {
                                                                                      label: 'Cancelar',
                                                                                      onClick: () => {return false}
                                                                                    }
                                                                                  ]
                                                                                })
                                                                              }
                                                                      ><Icon name="trash"/></Button>
                                                                    </Table.Col>
                                                                  </Table.Row>
                                                                )
                                                              }                              
                                                          </Table.Body>
                                                        </Table>
                                                      </Form.Group>

                                                    </div>
                                                  }
                                                />
                                          </Grid.Col>
                                        </Grid.Row>
                                      }
                        />
                      :null}
                    </Grid.Col>
                
                </Grid.Row>
              
              </div>
                          
            </div>
          </SiteWrapper>
    );
  }  
}
function mapStateToProps(state) {
  return {
    privilegios_object: state.catalogos_reducer.privilegios_object
  }
}
export default connect(mapStateToProps)(Tracking);
