import  React, { Component } from "react";
import {Grid, StampCard} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
const estilo= {color: "red", size: 100, "border-width": "5px", padding: "0px", "margin-top": "0.5em"};
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

//test
class AdminstracionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      arrastre:[]
    };
    this.funcionalidades= [];
  }
  componentWillMount =() =>{
   
    this.getfuntion("get", "", "", "funcionalidades/Administracion_Transportista/"+currentUser[0].perfil.id, "funcionalidades");
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "perfiles", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            if (stateVar === "funcionalidades") {
              
              let moduloArrastre =[];
              

              for(let item of returnVal){
                switch (item.nombre) {
                    case "Localidades":   
                      moduloArrastre.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                        <StampCard color="primary" icon="map" header={<a href="/localidades"><small>Localidades</small></a>} footer={""} />
                                      </Grid.Col>);
                    break;
                    case "Remolques":   
                      moduloArrastre.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                        <StampCard color="primary" icon="truck" header={<a href="/remolquesTransportista"><small>Tipos de Remolques</small></a>} footer={""} />
                                      </Grid.Col>);
                    break;

                  default:
                    break;
                }
              }
              
              this.setState({arrastre: moduloArrastre});





            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log("Error desconocido ...."+ err); })
  }
  render(){
    return (
      <SiteWrapper>
        <div className="container mt-3 administracion">
          { this.state.arrastre.length > 0 ? //**********************************************************Admistracion
            <div>
              <hr style={ estilo } />
              <h5>Configuración de Arrastre</h5>
              <Grid.Row>  {this.state.arrastre}  </Grid.Row>
            </div>
          : null}
        </div>
      </SiteWrapper>
    );
  }
}
export default AdminstracionPage;
