//import React, { Component } from 'react';
import axios from 'axios';
import * as conf from "../config/config";
import { validarToken } from "./JWT";

var user = JSON.parse(sessionStorage.getItem("usuario"));
var catalogosLogObject = { id: 0, nombreCatalogo: "", idUsuario: user? user.id: 0, accionFecha: null, metodo:"", data:[] };
//catalogos a excluir
const catalogosLogBlackList=["catalogosLog", "plataformasUsuariosPerfiles/username", "usuariosLog","validacionDatos/terrestre", "validacionDatos/naviera"];
export function Crud_error(ErrorCode=-99, Catalogo=""){
        switch(ErrorCode){
                case 400: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Solicitud incorrecta";
                case 401: return "( Codigo de error: "+ErrorCode+"),   Usted no tiene permiso para acceder a "+Catalogo +", Pongase en contacto con el administrador del sistema";
                case 403: return "( Codigo de error: "+ErrorCode+"),   Usted no tiene permiso para acceder a "+ Catalogo +", Pongase en contacto con el administrador del sistema";
                case 404: return "( Codigo de error: "+ErrorCode+"),   No se pudo acceder a "+ Catalogo +", intente mas tarde o pongase en contacto con el administrador del sistema";
                case 405: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Método no permitido";
                case 408: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Tiempo agotado, la solicitud no se llevó a cabo dentro del tiempo establecido";
                case 415: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": El servidor se niega a aceptar la solicitud porque el formato de carga útil está en un formato no compatible";
                case 500: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Algo ha ido mal en el servidor del sitio web";
                case 502: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Si se conecta a un servidor que actúa como una puerta de enlace o proxy, Es posible que los servidores que se comunican no están de acuerdo sobre el protocolo para intercambiar datos.";
                case 503: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": El servicio está temporalmente no disponible";
                default: return "Error desconocido";
        }
}
//                             principal   temporal default      id     parametros opcional          state              cerrar modal    si error actualizar
export function Crud_Catalogos(apiRaiz="", api="", metodo="get", id="", params=[], storeFunction="", stateElementos=[], hiddenModal="", arraytoUpdate=[], columna="", url= conf.api_raiz, filtro="" ){
  var tokenStore="tok";
  validarToken(sessionStorage.getItem(tokenStore));
  let instance = axios.create();
  if (url===""){
    url=conf.api_raiz;
  }
  instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
  instance.defaults.headers.common['Content-Type'] = 'application/json';
  instance.defaults.headers.common['Accept'] = 'application/json'; 
    
        switch(metodo){
                
                case "get":{
                                return axios.get(url+apiRaiz+filtro).then(res => {   
                                        
                                        if( storeFunction !== "" ){
                                               
                                                storeFunction(res.data);
                                        }
                                        return res.data;
                                });
                }
                case "post":
                        if(params !== "" && params !== null ){
                                return axios.post(url+apiRaiz, params).then(res => {
                                       
                                        catalogosLogObject.nombreCatalogo= apiRaiz+filtro;
                                        catalogosLogObject.metodo= metodo;
                                        catalogosLogObject.data= JSON.stringify(res.data);
                                        if( !catalogosLogBlackList.includes(apiRaiz) ){ Crud_Catalogos("catalogosLog", "", "post", "", catalogosLogObject); }
                                        if( typeof stateElementos === "object" ){
                                                
                                                if(api === apiRaiz){
                                                        
                                                        if(res.data.length > 0 ){
                                                                res.data.forEach((element,i) => {
                                                                        console.log(element)
                                                                        stateElementos.push(element);
                                                                });
                                                        }else{
                                                           stateElementos.push(res.data);     
                                                        }

                                                        console.log(stateElementos)
                                                        
                                                        if( storeFunction !== "" ){ storeFunction(stateElementos); }
                                                        if( hiddenModal !== "" ){ hiddenModal(); }
                                                        return res.data;
                                                }else{
                                                       
                                                        stateElementos.map((elemento, index) =>{
                                                                if(elemento["id"] === id){
                                                                        if(columna !== "" && elemento[columna]){
                                                                               
                                                                                arraytoUpdate[columna].push(res.data);
                                                                        }
                                                                }
                                                                if(elemento["id"] === params["id"]){
                                                                        if( storeFunction !== "" ){ storeFunction(arraytoUpdate); }
                                                                } return elemento;
                                                        })
                                                        return arraytoUpdate;
                                                }
                                        }else{
                                        
                                                return res.data;
                                        }
                                });
                        }else{
                          return false;
                        }
                case "put":
                        
                        if(params !== "" && params !== null ){
                                
                                return axios.put(url+apiRaiz, params).then(res => {
                                        catalogosLogObject.nombreCatalogo= apiRaiz+filtro;
                                        catalogosLogObject.metodo= metodo;
                                        catalogosLogObject.data= catalogosLogObject.data= JSON.stringify(res.data);
                                        if( !catalogosLogBlackList.includes(apiRaiz) ){ Crud_Catalogos("catalogosLog", "", "post", "", catalogosLogObject); }
                                        if( typeof stateElementos === "object" ){
                                                if(api === apiRaiz){
                                                        stateElementos.map((elemento, index) =>{
                                                                if(elemento["id"] === params["id"]){
                                                                        stateElementos[index]= res.data
                                                                } return elemento;
                                                        })
                                                        if( storeFunction !== "" ){ storeFunction(stateElementos); }
                                                        if( hiddenModal !== "" ){ hiddenModal(); }
                                                        return res
                                                }else{
                                                  return res.data;
                                                }
                                        }else{
                                                if( hiddenModal !== "" ){ hiddenModal(); }
                                                return res.data;
                                        }
                                });
                        }else{
                          return false;
                        }
                case "delete":
                        if(id !== "" && id !== null ){
                                return axios.delete(url+apiRaiz+"/"+id).then(res => {
                                        catalogosLogObject.nombreCatalogo= apiRaiz+filtro;
                                        catalogosLogObject.metodo= metodo;
                                        catalogosLogObject.data=JSON.stringify({ id: id,  Respuesta: res.data}) ;
                                        if( !catalogosLogBlackList.includes(apiRaiz) ){ Crud_Catalogos("catalogosLog", "", "post", "", catalogosLogObject); }
                                        if(api === apiRaiz){
                                                stateElementos.map((elemento, index) =>{
                                                        if(elemento["id"] === id){
                                                                stateElementos.splice(index, 1)
                                                        } return elemento;
                                                })
                                                if( storeFunction !== "" ){ storeFunction(stateElementos); }
                                                if( hiddenModal !== "" ){ hiddenModal(); }
                                                return res.data;
                                        }else{

                                                var idFila=""; var ban= true;
                                                if(id.length){
                                                        for (let item of id) {
                                                                if(item !== "/" && ban){
                                                                        idFila= idFila+item
                                                                }else{ if(item === "/"){ban=false;} }
                                                        }

                                                }else{  idFila=id}
                                                if( typeof stateElementos === "object" ){
                                                stateElementos.map((elemento, index) =>{
                                                        if(elemento["id"] === idFila){
                                                                stateElementos[index]=arraytoUpdate;
                                                                return arraytoUpdate;
                                                        } return elemento;
                                                })
                                                }
                                                return res.data;
                                        }
                                });
                        }else{
                          let restpuesta= false;
                          if( typeof params === "object" ){
                            return axios.delete(url+apiRaiz, params).then(res => {
                                catalogosLogObject.nombreCatalogo= apiRaiz+filtro;
                                catalogosLogObject.metodo= metodo;
                                catalogosLogObject.data=JSON.stringify({ data: params,  Respuesta: res.data}) ;
                                if( !catalogosLogBlackList.includes(apiRaiz) ){ Crud_Catalogos("catalogosLog", "", "post", "", catalogosLogObject); }
                                    if(res){ restpuesta= res}else{ restpuesta=false }
                                });
                          }
                          return restpuesta;
                        }
                default: return null;
        }
}

export default Crud_Catalogos;
