import React, { Component, useRef }                      from "react";
import { Button, Form, Icon, Grid, Card, List } from "tabler-react";
import { Crud_Catalogos, Crud_error }            from '../../../herramientas/Crud_Catalogos';
import { Remolques } from ".";


class RemolquesTransportistas extends Component {
  
  constructor(props) {
    
    super(props);
  }
 

  componentWillMount =() => {
      ///REllenamos los valores de los inputs a setear antes de asignarlos a un total disponible.
      let arrayInputsDisponibles = Array(this.props.stor.props.data.remolquesTransportistas.length).fill(0);
      let arrayInputsReportados = Array(this.props.stor.props.data.remolquesTransportistas.length).fill(0);
      this.state = {
                  idTransportista:this.props.stor.props.data.remolquesTransportistas[0].idTransportistaLocalidades.idTransportista,
                  usuario:this.props.stor.usuario,
                  estatus:false,
                  accion:this.props.accion, //'nuevo'// modificar, nuevo
                  FArray:[],
                  hasFocus:false,
                  selected:"",
                  currentTap:0,
                  crudbutonEnable:false,
                  IArrayDisponibles:arrayInputsDisponibles,
                  IArrayReportados:arrayInputsReportados,
                  remolquesTransportista:this.props.stor.props.data.remolquesTransportistas,
                  bandera:false,
                  
                
      }; 
      this.handleSubmit = this.handleSubmit.bind(this);
  
  
  }

  myFormato(date) {
    function pad(number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    }
    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      " " +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds())
    );
  }

  detectedChange = (newArray)=>{


    let camposmodificados = "Localidad: " + this.props.stor.props.data.corredor.nombre;
    let bitacora = 113
    let remolques = this.props.stor.props.data.remolques
    let bandera =  false;

    this.props.stor.props.data.remolquesTransportistas.map((typeRemolque, i) => {
      
        if (camposmodificados.length > 0) {
          camposmodificados = camposmodificados + ",  ";
        }
          camposmodificados = camposmodificados +  typeRemolque.idRemolque.nombre+"("+ typeRemolque.tipoCorredor+ ")"+":( ";
          camposmodificados =
            camposmodificados + "reportados de" +
            "0" +
            " a " +
            newArray[i].total_registrado +
            ")";
      
      
    
    })


    if(newArray[0].comentarios != "" && this.props.accion != "modificar" ){

      camposmodificados = camposmodificados +  "  Comentarios:( ";
      camposmodificados =
        camposmodificados +
        "" +
        " a " +
        newArray[0].comentarios +
        ")";

    }

   
        
      

    return { id: bitacora, descripcion: camposmodificados };

  }


  GetInputsDisponibles = ()=>{

      let tempArrayInputs = this.state.IArrayDisponibles; 
      let remolques = this.props.stor.props.data.remolques
        
   return this.props.stor.props.data.remolquesTransportistas.map((typeRemolque, i) => {
                if(remolques && !this.state.bandera){
                  remolques.map((remol)=> { 
                    if(remol.idRemolque == typeRemolque.idRemolque.idRemolque ){
                      tempArrayInputs[i] = remol.totalDisponible; 
                    }
                  });
                }
            return  <div key={i}>
              <Form.Input  type="number"  value={this.state.IArrayDisponibles[i]}  name={typeRemolque.idRemolque.idRemolque} className="remolques"   id={typeRemolque.idRemolque}  onChange={e => { this.handleChange(e.target.value,i) }} >{this.state.IArrayDisponibles[i]}dddddd</Form.Input>
              </div>
          })
  
  }

  GetInputsReportadas = ()=>{

    let tempArrayInputs = this.state.IArrayReportados; 
    let remolques = this.props.stor.props.data.remolques
        
   return this.props.stor.props.data.remolquesTransportistas.map((typeRemolque, i) => {
                if(remolques && !this.state.bandera){
                  remolques.map((remol)=> { 
                    if(remol.idRemolque == typeRemolque.idRemolque.idRemolque ){
                      tempArrayInputs[i] = remol.totalRegistrado; 
                    }
                  });
                }

            
            return  <div key={i}>
              {this.state.accion === "modificar" ? 
              <Form.StaticText  type="number" >{this.state.IArrayReportados[i]}</Form.StaticText> 
              :  
              <>
              
              <Form.Input  type="number"  value={this.state.IArrayReportados[i]}  name={typeRemolque.idRemolque.idRemolque} className="remolques"   id={typeRemolque.idRemolque}  onChange={e => { this.handleChange(e.target.value,i,true) }} >{this.state.IArrayReportados[i]}</Form.Input>
              <Form.Input type="hidden" value={typeRemolque.id} name="idTransportistaRemolque" ></Form.Input>
              </>
              }
              </div>
          })
  
  }
  
  handleChange = (value,indice,reportados = false) => {
    this.setState({bandera:true})
    let elements = document.getElementsByClassName("remolques");
    let bandera =  false;
    let arrayTempInputs = !reportados ? this.state.IArrayDisponibles : this.state.IArrayReportados;

    for (let i = 0; i < elements.length; i++) {
      
      if(elements[i].value > 0 ){
        bandera = true;
      }
      
  }
  
    arrayTempInputs[indice] = value;
    bandera ? this.setState({crudbutonEnable:true}) : this.setState({crudbutonEnable:false})

  }


  


  

  handleSubmit(event) {
  
    event.preventDefault();
    const form  = event.target;
    const data = new FormData(form);
    let JSON_REQUEST = [];
    const idTransportista = this.state.idTransportista;
    const idUsuario       = this.state.usuario;
    const accion          = this.props.accion;
    const remolques       = this.props.stor.props.data.remolquesTransportistas;
    

    let i = 0;
    data.forEach(function(value, key){
      console.log(value)
        if(key !== 'comentarios' ){
          if(key !== 'idTransportistaRemolque'){
            JSON_REQUEST.push({
              "idTransportistaRemolque":0,
              "total_disponible":0,
              "total_apartado":0,
              "total_ocupado":0,
              "total_registrado":Number(value),
              "comentarios":data.get('comentarios')
        
            });
            i++;
          }else{
            JSON_REQUEST[i-1].idTransportistaRemolque  = Number(value); 
          }

          if(accion === "modificar" && remolques[0].disponibilidadRemolques){
            console.log("enenenenennenen")
                remolques.map(remolque=>{
                  if(remolque.idRemolque == Number(key)){
                    JSON_REQUEST[i].total_registrado  =  remolque.totalRegistrado;
                    JSON_REQUEST[i]["idDisponibilidadTransportistaRemolque"] = remolque.idRemolqueDisponiblidad;
                    JSON_REQUEST[i].total_disponible = Number(value);
                    
                 }
                })
          }
        }
        
    });

    


    if(accion === "modificar"){
      this.crud_put(JSON_REQUEST)
    }else{


      this.crud_post(JSON_REQUEST)
    }


    let bitacora = this.detectedChange(JSON_REQUEST);

    let arrastreLogObject = {
      idBitacoraMonitoreo: bitacora.id,
      idUsuario: idUsuario,
      idTransportista: idTransportista,
      accionFecha: this.myFormato(new Date()),
      descripcion: bitacora.descripcion,
      idPlataformaTipo: 1,
      Navigator: false,
    };
    
    Crud_Catalogos(
      "arrastreLog",
      "",
      "post",
      "",
      arrastreLogObject
    );
    
    this.props.closeModal();
  
}

  
  delete()    { this.props.getfuntion("delete", "", this.state.id); }
  crud_put(request) {  this.props.getfuntion("put",request,"","disponibilidadRemolquesTransportistas"); }
  crud_post(request) { this.props.getfuntion("post",request,"","disponibilidadRemolquesTransportistas"); }
 

 
  render() {
  
   const GetInputsLabels = ()=>{
      return(<>
       {this.props.stor.props.data.remolquesTransportistas.map((remolque, i) => {
        return (
          <>
            <Form.Input  disabled value={remolque.idRemolque.nombre + "("+ remolque.tipoCorredor+")"}  />
          </>
        );
      
    })}
      </>)
     
    }




   
   
    return (
      
      <div>
 {this.state.accion === "modificar" ? <h1> {this.props.elementos.nombre} </h1> : null}
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
            <Card title="Remolques de Transportista" isCollapsible isCollapsed={false} body={
                  <Form  onSubmit={this.handleSubmit}  className="FormRemolques">
                  <Grid.Row>
                    <Grid.Col>
                      <Form.Group label="Tipo de Remolque">
                     <GetInputsLabels></GetInputsLabels>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group  label={<>Cantidad de remolques a reportar<label className="text-danger">*</label></>}>
                      {this.GetInputsReportadas()}
                        
                      </Form.Group>
                    </Grid.Col>
                   {this.state.accion === "modificar" ? 
                    <Grid.Col>
                      <Form.Group  label={<>Cantidad de remolques disponibles<label className="text-danger">*</label></>}>
                      {this.GetInputsDisponibles()}
                        
                      </Form.Group>
                    </Grid.Col>
                   :null}
                   
                  </Grid.Row>  

                  <Grid.Row className="unidades">
                    <Grid.Col>
                      <Form.Label>Ingresa tus comentarios</Form.Label>
                      <Form.Textarea id="comentarios" readonly={this.state.accion !== "modificar" ? false: true} name="comentarios" className="comentarios" placement="top" value={this.state.remolquesTransportista[0] ? this.state.remolquesTransportista[0].comentarios: ""}></Form.Textarea> 
                    </Grid.Col>  
                    
                  </Grid.Row>
                  <Grid.Row className="unidades">
                      <Grid.Col sm={12} lg={12}>
                                <div className="float-right">
                                    
                                    <span class="badge">
                                        <Button  size="sm" color="primary" disabled={!this.state.crudbutonEnable}
                                        >
                                        <span class="badge"><Icon  name="save" />{this.props.accion}</span>
                                        </Button>
                                    </span>
                                   
                                  
                                </div>
                    </Grid.Col>
                  </Grid.Row>
                  </Form> 
                  
                
                }
            />
          </Grid.Col>
        </Grid.Row>

        
      </div>
    );
  }
}
export default RemolquesTransportistas;
