import React, { useEffect, useState, useRef, useCallback } from 'react';
import './NoticiasCintillo.css'; 
import Crud_Catalogos from '../../herramientas/Crud_Catalogos';
import axios from 'axios';
import { api_raiz } from '../../config/config';

// Función para obtener datos de usuario y perfil de sessionStorage
const obtenerDatosUsuario = () => {
  const usuario = JSON.parse(sessionStorage.getItem("usuario"));
  const token = sessionStorage.getItem("tok");
  const perfil = JSON.parse(sessionStorage.getItem("currentUser"));
  return {
    id: usuario?.id,
    token,
    perfilId: perfil?.[0]?.perfil?.id
  };
};

const NoticiasCintillo = () => {
  const { id, token, perfilId } = obtenerDatosUsuario();
  const [noticias, setNoticias] = useState([]);
  const [mostrarCintillo, setMostrarCintillo] = useState(false); 
  const [animDuration, setAnimDuration] = useState(90);
  const cintilloRef = useRef(null);

  // Fetch de noticias y validación del perfil
  const fetchNoticias = useCallback(async () => {
    try {
      if (perfilId && token) {
        const respuesta = await axios.get(
          `${api_raiz}bloque/usuario/${id}/perfil/${perfilId}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          }
        );

        const noticiaItem = respuesta.data.find(item => item.nombre === "Noticias");
        if (noticiaItem) {
          setMostrarCintillo(true);

          // Obtiene las noticias y filtra por fecha
          const response = await Crud_Catalogos("noticias", "noticias", "get");
          const hoy = new Date();
          const noticiasFiltradas = response.filter(noticia => {
            const fechaPublicacion = new Date(noticia.fechaPublicacion);
            const fechaExpiracion = new Date(noticia.fechaExpiracion);
            return fechaPublicacion <= hoy && hoy <= fechaExpiracion;
          });
          setNoticias(noticiasFiltradas);

          ajustarDuracionAnimacion(noticiasFiltradas.length);
        } else {
          setMostrarCintillo(false);
        }
      } else {
        console.error("PerfilId o token no encontrados.");
      }
    } catch (error) {
      console.error("Error al recuperar noticias", error);
    }
  }, [id, perfilId, token]);

  // Ajusta la duración de la animación según el ancho del cintillo
  const ajustarDuracionAnimacion = useCallback((numNoticias) => {
    if (cintilloRef.current) {
      const anchoContenido = cintilloRef.current.scrollWidth;
      const velocidad = 350;
      const nuevaDuracion = (anchoContenido * 2) / velocidad;
      setAnimDuration(nuevaDuracion);
    }
  }, []);

  
  useEffect(() => {
    fetchNoticias();                            //m   s     ms
    const intervalo = setInterval(fetchNoticias, 1 * 60 * 1000); 
    return () => clearInterval(intervalo); // Limpia el intervalo al desmontar
  }, [fetchNoticias]);

  return (
    <div>
      {mostrarCintillo && noticias.length > 0 && (
        <div className="cintillo-container">
          <div 
            className="cintillo" 
            ref={cintilloRef} 
            style={{ animationDuration: `${animDuration}s` }}
          >
            {[...noticias, ...noticias].map((noticia, index) => (
              <span key={index} className="cintillo-noticia">
                {noticia.noticia}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NoticiasCintillo;
