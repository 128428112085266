// @flow
import * as React from "react";
import { withRouter } from "react-router-dom";
import { Site, Nav, RouterContextProvider } from "tabler-react";
import type { NotificationProps } from "./tabler-react";

import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import NoticiasCintillo from "./components/NoticiasCintillo/NoticiasCintillo";

type Props = {|
  +children: React.Node,
|};

type State = {|
  notificationsObjects: Array<NotificationProps>,
|};

type subNavItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +LinkComponent?: React.ElementType,
|};

type navItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +active?: boolean,
  +LinkComponent?: React.ElementType,
  +subItems?: Array<subNavItem>,
  +useExact?: boolean,
|};

const navBarItems: Array<navItem> = JSON.parse(sessionStorage.getItem("navBarItems"));

var user= JSON.parse(sessionStorage.getItem("usuario"));
var perfil= JSON.parse(sessionStorage.getItem("perfil"));


const accountDropdownProps = {
  avatarURL: "./user/user.png",
  name: user ? user.persona? user.persona.nombre+" "+user.persona.aPaterno+" "+ user.persona.aMaterno: "":"",
  description: perfil ? perfil.nombre: "",
  options: [
    /*
    { icon: "settings", value: "Settings" },
    { icon: "mail", value: "Inbox", badge: "6" },
    { icon: "send", value: "Message" },
    { isDivider: true },
    { icon: "help-circle", value: "Need help?" },*/
    { icon: "user", value: "Perfil", to: "/perfilusuario"},
    { icon: "log-out", value: "Salir", to: "/logout"},
  ],
};

class SiteWrapper extends React.Component<Props, State> {
  state = {
    notificationsObjects: [
      {
        unread: true,
        avatarURL: "demo/faces/male/41.jpg",
        message: (
          <React.Fragment>
            <strong>Skyangel</strong> Esto es una notificación
          </React.Fragment>
        ),
        time: "10 hace 10 min",
      },
    ],
  };

  render(): React.Node {
    //const notificationsObjects = this.state.notificationsObjects || [];
    //const unreadCount = this.state.notificationsObjects.reduce( (a, v) => a || v.unread, false );
    return (
      <Site.Wrapper
        headerProps={{
          href: "/",
          alt: "Sky Angel",
          imageURL: "images/logo.png",
          navItems: (
            <Nav.Item type="div" className="d-none d-md-flex">
              {/*
              <Button
                href="https://github.com/tabler/tabler-react"
                target="_blank"
                outline
                size="sm"
                RootComponent="a"
                color="primary"
              >
                Codigo Fuente
              </Button>
              */}
            </Nav.Item>            
          ),
          /*                               Notificaciones
          notificationsTray: {
            notificationsObjects,
            markAllAsRead: () =>
              this.setState(
                () => ({
                  notificationsObjects: this.state.notificationsObjects.map(
                    v => ({ ...v, unread: false })
                  ),
                }),
                () =>
                  setTimeout(
                    () =>
                      this.setState({
                        notificationsObjects: this.state.notificationsObjects.map(
                          v => ({ ...v, unread: true })
                        ),
                      }),
                    5000
                  )
              ),
            unread: unreadCount,
          },*/
          accountDropdown: accountDropdownProps,
        }}
        navProps={{ itemsObjects: navBarItems }}
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          links: [

          ],
          note:
            "", //pie de pagina
          copyright: (
            <React.Fragment>
              Copyright © 2024
              <a href="."> Skyangel
              </a>.
            </React.Fragment>
          ),
          /*
          nav: (
            <React.Fragment>
              <Grid.Col auto={true}>
                <List className="list-inline list-inline-dots mb-0">
                  <List.Item className="list-inline-item">
                    <a href="./docs/index.html">Documentation</a>
                  </List.Item>
                  <List.Item className="list-inline-item">
                    <a href="./faq.html">FAQ</a>
                  </List.Item>
                </List>
              </Grid.Col>
            </React.Fragment>
          ),*/
        }}
      >
        
        <NotificationContainer/>
        {this.props.children}
      </Site.Wrapper>
    );
  }
}

export default SiteWrapper;
