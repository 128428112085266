//Author F.R. Betancourt
import React, { useState, useEffect } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
import { element, object } from "prop-types";
import { Input } from "@mui/base";

const RemolquesForm = ({
  stor,
  accion,
  elementos,
  getfuntion,
  cerrarModal,
}) => {

  const [formulario, setFormulario] = useState([]);
  const [crudbutonEnable, setCrudbutonEnable] = useState(true); // Se desabilita cuando se hace click
  const [selectLocalidades, setSelectLocalidades] = useState();
  const [localidadesOpcion, setLocalidadesOpcion] = useState();
  const [localidadesOpciones, setLocalidadesOpciones] = useState();
  const [remolquesOpcion, setRemolquesOpcion] = useState();
  const [remolquesOpciones, setRemolquesOpciones] = useState();
  const [corredorOpcion, setCorredorOpcion] = useState();
  const [corredorOpciones, setCorredorOpciones] = useState();
  const [apartado,setApartado] = useState('');

  const [estatus, setEstatus] = useState();
  var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  let data  = [];


 

  useEffect(() => {

    let corredoresTipo = [{value:"F", label:"FORANEO"},{ value:"L" ,label:"LOCAL"}]

    if (accion === "nuevo") {
      let localidades = [];
      let bandera =  true;
      let dataJson = [];
      
      elementos.sort((a,b) => (a.idTransportistaLocalidades.localidades.nombre > b.idTransportistaLocalidades.localidades.nombre) ? 1 : ((b.idTransportistaLocalidades.localidades.nombre > a.idTransportistaLocalidades.localidades.nombre) ? -1 : 0))

      let arrayLocalTem = [...elementos];
     
          stor.localidadesTransportistas_object.forEach((localidad,index) => {
            
            let localidades = new Object();
                localidades.name = localidad.localidades.nombre;
                localidades.code = localidad.id;
                localidades.remolques = [];   
                  stor.remolquesTipo_object.forEach((remolque,index) => {
                    let remolques = new Object();
                    remolques.name = remolque.nombre;
                    remolques.code = remolque.idRemolque;
                    remolques.corredores = [];
                    localidades.remolques.push(remolques);
                    corredoresTipo.forEach((corredor,index) => {
                        let corredores = new Object();
                        corredores.name = corredor.label;
                        corredores.code = corredor.value;
                        remolques.corredores.push(corredores);
                      })
                  })
                    dataJson.push(localidades)
        });

        if(arrayLocalTem.length > 0){
          arrayLocalTem.forEach(element => {
            dataJson.forEach((localidad,i) =>{
              if(localidad.code == element.idTransportistaLocalidades.id){
                localidad.remolques.forEach((remolque,j)=>{
                  if(remolque.code == element.idRemolque.idRemolque){
                    remolque.corredores.forEach((corredor,k) =>{
                      if(corredor.code == element.tipoCorredor){  
                        dataJson[i].remolques[j].corredores.splice(k, 1);
                      }

                    })
                  }
                })
              }
            })
          })
        }
      

      setLocalidadesOpciones(dataJson);
  
    } else {
      setCorredorOpciones(corredoresTipo)
      setCorredorOpcion([{value:elementos.tipoCorredor , label:elementos.tipoCorredor == "F" ? "FORANEO" : "LOCAL" ,selected:true}])
      setRemolquesOpcion([{value:elementos.idRemolque.id , label:elementos.idRemolque.nombre ,selected:true}])
      setLocalidadesOpcion([{value:elementos.idTransportistaLocalidades.id , label:elementos.idTransportistaLocalidades.localidades.nombre ,selected:true}])
      setApartado(elementos.apartado)
      setFormulario(elementos);
    }
  }, []);



  
  useEffect(()=>{

  },[apartado])


  const handleHorario = (e) => {

    console.log(e.target.value)
    setApartado( ( e.target.value));
    
  };


  const handleLocalidadeChange = (obj,event) => {


    setLocalidadesOpcion(obj);
    setRemolquesOpciones(obj.remolques);
    setRemolquesOpcion(null);

    let arrayFiltersTemp;
    
    

    if((event&& event.action === "select-option")){
      arrayFiltersTemp = [{"name": obj.name,"value": obj.code}];
      setSelectLocalidades(arrayFiltersTemp);
    }
  };
 
  // handle change event of the language dropdown
  const handleRemolqueChange = (obj) => {
    setRemolquesOpcion(obj);
    if(obj && obj.length > 0){
      setCorredorOpciones(obj[0].corredores) 
    }else{
      setCorredorOpciones([])
    }
    setCorredorOpcion(null)
  };

  const handleCorredorChange = (obj) => {
    setCorredorOpcion(obj);
    setCrudbutonEnable(true)
  };




  const isEnable = (Accion) => {

    let borrar = "corredores_borrar";
    let actualizar = "corredores_editar";
    let agregar = "corredores_agregar";
    var privilegios = stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "modificar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "nuevo") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const enableSave = () => {
  
    if (corredorOpcion ?   true : false) {
      return false;
    } else {
      return true;
    }
  };

  const guardarLocalidades = () => {
    if (accion === "modificar") crud_put();
    else crud_post();
    cerrarModal();
  };

  const crud_delete = () => {
    setCrudbutonEnable(false);
    getfuntion("delete", "", elementos.id);
  };

  const crud_put = () => {
    setCrudbutonEnable(false);
   
    console.log(apartado)
    formulario.idTransportistaLocalidades = formulario.idTransportistaLocalidades.id;
    formulario.idRemolque =  formulario.idRemolque.idRemolque;
    formulario.tipoCorredor =  corredorOpcion.value ? corredorOpcion.value : formulario.tipoCorredor
    formulario.apartado  = apartado ? apartado : formulario.apartado;

    console.log(formulario)
    getfuntion("put", formulario);
  };
  const crud_post = () => {
    
      
      remolquesOpcion.map((remolque) =>{
        console.log(apartado)
         formulario.push({ idTransportistaLocalidades: Number(localidadesOpcion.code), idRemolque: Number(remolque.code) , tipoCorredor: corredorOpcion.code ,apartado:apartado})
      })
    
    

    setCrudbutonEnable(false);
    getfuntion("post", formulario);
  };
  return (
    <div>
    
      {accion === "modificar" ?  
        <div>
        <Grid.Row>
        <Grid.Col md={12}>
          <label for="remolques">Localidad</label>
          <Select   name="localidades"  value={localidadesOpcion}    />
        </Grid.Col>

        </Grid.Row> 
        <Grid.Row>
          <Grid.Col md={12}>
              <label for="remolques">Selecciona los remolques a utilizar</label>
                <Select   name="remolques" value={remolquesOpcion} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
      
        <Grid.Col md={12}>
            <label for="remolques">Selecciona los corredores a utilizar</label>
          <Select   name="remolques" value={corredorOpcion} onChange={(e,a)=>{ setCorredorOpcion(e);  }} options={corredorOpciones} />
        </Grid.Col>
       </Grid.Row>

       <Grid.Row>
       <Grid.Col md={12}>
            <label for="apartado">Horario de apartado</label>
            <Input  type="time" name="apartado" value={apartado} onChange={handleHorario}/>
        </Grid.Col>
       </Grid.Row>
       </div>
        : 
        <div>
        <Grid.Row>
        <Grid.Col md={12}>
          <label for="remolques">Selecciona las localidades a trackear</label>
          
          <Select   name="localidades"  value={localidadesOpcion}  options={localidadesOpciones}  onChange={handleLocalidadeChange}
          getOptionLabel={x => x.name}
          getOptionValue={x => x.code}  />
        </Grid.Col>

        </Grid.Row> 
        <Grid.Row>
          <Grid.Col md={12}>
              <label for="remolques">Selecciona los remolques a utilizar</label>
              
                <Select  isMulti name="remolques" value={remolquesOpcion} onChange={handleRemolqueChange} options={remolquesOpciones}
                 getOptionLabel={x => x.name}
                 getOptionValue={x => x.code} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
      
        <Grid.Col md={12}>
            <label for="remolques">Selecciona los corredores a utilizar</label>
           
          <Select   name="remolques" value={corredorOpcion} onChange={handleCorredorChange} options={corredorOpciones} 
          getOptionLabel={x => x.name}
          getOptionValue={x => x.code}/>
        </Grid.Col>
       </Grid.Row>

       <Grid.Row>
       <Grid.Col md={12}>
            <label for="apartado">Tiempo de apartado</label>
            <Input   name="apartado" type="time" value={apartado} onChange={handleHorario} />
        </Grid.Col>
       </Grid.Row>
       </div>
       }


      <div className="float-right">
        {isEnable(accion) ? (
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              disabled={enableSave() || !crudbutonEnable}
              onClick={guardarLocalidades}
            >
              <span class="badge">
                <Icon link={true} name="save" />
                Guardar
              </span>
            </Button>
          </span>
        ) : null}
        {accion === "modificar"  ? (
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              onClick={() => crud_delete()}
              disabled={!crudbutonEnable}
            >
              <span class="badge">
                <Icon link={true} name="trash" />
                Borrar
              </span>
            </Button>
          </span>
        ) : null}
      </div>
    </div>
  );
};
export default RemolquesForm;
